/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 200;
  src: url(/assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: Open Sans;
  font-style: normal;
  src: url(/assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  src: url(/assets/fonts/OpenSans/OpenSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 900;
  src: url(/assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}

.sleek-dialog-container .mat-dialog-container {
  padding: 0px;
  min-width: 350px;
}

.mat-paginator-icon {
  color: var(--fuse-primary);
}

hr.separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mat-slide-toggle-thumb {
  height: 15px !important;
  width: 15px !important;
  background-color: white !important;
  position: relative !important;
  top: 5.5px !important;
  left: 2.5px !important;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: theme('colors.tilled-neutral.300') !important;
  height: 20px !important;
  border-radius: 100px !important;
  //width: 40px !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--fuse-primary) !important;
  height: 20px !important;
  border-radius: 100px !important;
  //width: 40px !important;
}
.mat-mini-fab {
  min-height: 20px !important;
  min-width: 20px !important;
}

.ngx-mat-color-canvas .color-canvas-row .zone-strip {
  margin-left: 214px !important;
  margin-top: -201px !important;
}
//.mat-slide-toggle.mat-warn.mat-checked

.tilled-card-styling {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  //px-8 py-6;
}

.mat-calendar-previous-button {
  color: var(--fuse-primary) !important;
}

.mat-calendar-next-button {
  color: var(--fuse-primary) !important;
}
